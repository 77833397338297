
































































































































































































































































































































































































































































































































































































































































































































import {
  factions,
  booleanOptions,
  realmOptions,
  websiteAdvCutOptions,
  shiftBossOptions,
  shiftDifficultyOptions,
  difficultyAbrevations,
  advCutOptionsForCheck,
} from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import GoldTextField from "@/components/custom/gold-text-field";
import {
  SheetShift,
  SheetCustomer,
  Customer,
  BoostType,
} from "@/interfaces/Raids";
import moment, { Moment } from "moment-timezone";
import RaidBookingInformationDialog from "@/components/booking/dialogs/RaidBookingInformationDialog.vue";
import RaidBookingMenu from "@/components/booking/RaidBookingMenu.vue";
import { formatPotToGold } from "@/shared/formatPotToGold";

export default Vue.extend({
  name: "ShiftBookingAndHistorySheetForm",
  components: { RaidBookingMenu, GoldTextField, RaidBookingInformationDialog },
  data: () => ({
    valid: false,
    factions: factions,
    runID: "",
    advCutOptions: websiteAdvCutOptions,
    advCut: websiteAdvCutOptions[0],
    pot: "0",
    collectedGold: "0",
    shifts: [] as Array<SheetShift>,
    customer: [] as Array<SheetCustomer>,
    bookings: [],
    paymentRealm: "",
    type: Object.values(shiftBossOptions)[1] as BoostType,
    typeOptions: Object.values(shiftBossOptions),
    paymentRealmOptions: realmOptions,
    paymentFaction: "",
    booleanOptions: booleanOptions,
    note: "",
    btag: "",
    paid: false,
    bookingsInfoRaid: {} as SheetShift,
    buyer: "",
    time: "",
    difficultyFilter: "",
    raiderIo: "",
    loading: false,
    snackbar: false,
    dialog: false,
    deleteConfirmDialog: false,
    snackbarText: "Should not show",
    deleteConfirmCustomer: {},
    deleteConfirmAction: "",
    snackbarColor: "red",
    timeout: 2000,
    bookingID: "",
    leaderId: "",
    selectedRaid: {},
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    nameRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
      (v: string) =>
        (!!v && v.includes("-")) ||
        "Customer name must be in name-realm format",
    ],
    required: [(v: any) => !!v || "Field is required"],
    paymentRealmRule: [
      (v: any) => !!v || "Field is required",
      (v: any) =>
        (!!v &&
          realmOptions
            .map((element: string) => element.toLowerCase())
            .includes(v.toLowerCase())) ||
        "Unknown Realm selected",
    ],
    advCutRules: [
      (v: any) => !!v || "Field is required",
      (v: any) =>
        (!!v &&
          advCutOptionsForCheck
            .map((element: string) => element.toLowerCase())
            .includes(v.toLowerCase())) ||
        "Unknown Fee option",
    ],
  }),
  methods: {
    difficultyOptions() {
      return shiftDifficultyOptions;
    },
    setPaymentRealm(e) {
      const value = e.target.value;
      if (value.includes("-")) {
        this.paymentRealm = value.split("-")[1];
      }
    },
    formatPotToGold,
    goBack(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
    fireEvent() {
      if (this.deleteConfirmAction) {
        this[this.deleteConfirmAction]();
      }
    },
    calculateFee(booking: SheetCustomer): number {
      let amountOwnedPerBooking = 0;
      if (booking.feeOption === "FTB" || booking.feeOption === "TAKEN") {
        amountOwnedPerBooking = Number(
          (BigInt(booking.price) * BigInt(1000) * BigInt(300)) / BigInt(1000)
        );
      } else {
        amountOwnedPerBooking =
          Number(
            (BigInt(booking.price) * BigInt(1000) * BigInt(100)) / BigInt(1000)
          ) > 100000
            ? 100000
            : Number(
                (BigInt(booking.price) * BigInt(100) * BigInt(1000)) /
                  BigInt(1000)
              );
      }
      return amountOwnedPerBooking;
    },
    moment(date?): Moment {
      return moment(date);
    },
    getFactionIcon(item: string): string {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      }
      return require("@/assets/Logo/alliance_small.png");
    },
    formatDate(date: string | Date, format: string): string {
      return moment(date).format(format);
    },
    SetIdForBooking(id: string): void {
      this.runID = id;
      Object.assign(
        this.selectedRaid,
        this.shifts.find((raid: any) => raid.run == id)
      );
    },
    resetForm(): void {
      const form: any = this.$refs.form;
      form.reset();

      this.advCut = "FTB";
      this.bookingID = "";
      this.runID = "";
      this.paid = false;
    },
    loadRuns(): Array<SheetShift> {
      this.shifts = this.$store.state.sheetShifts || [];
      return this.shifts;
    },
    loadCustomer(): Array<SheetCustomer> {
      this.customer = this.$store.state.sheetCustomers || [];
      return this.customer;
    },
    async bookCustomer(shouldBook: boolean): Promise<void> {
      const form: any = this.$refs.form;
      if (shouldBook) {
        this.loading = true;
        form.validate();
        const found = this.shifts.find(
          (raid: SheetShift) => raid.run === this.runID
        );
        if (found) {
          const raid: SheetShift = found as SheetShift;
          if (raid) {
            let booking: any = {
              raidId: raid.run,
              section: "shift",
              time: raid.time,
              advCut: this.advCut.toUpperCase(),
              typeOfBoost: this.type,
              buyer: this.buyer.trim(),
              price: parseInt(this.pot),
              collectedGold: this.collectedGold
                ? parseInt(this.collectedGold)
                : 0,
              paymentRealm: this.paymentRealm.replace(/\s+/g, ""),
              faction: this.paymentFaction,
              note: "Placeholder",
              paid: this.paid,
            };

            booking.note = `${this.note ? this.note : ""} ${
              this.btag ? this.btag : ""
            }`;

            const response = await this.$store.dispatch(
              "createBooking",
              booking
            );
            if (response.status === 200) {
              this.snackbarToggle({
                message: "Your booking has been successfully created.",
                color: "green",
              });
              this.buyer = "";
              this.collectedGold = "0";
              this.paymentRealm = "";
              this.paymentFaction = "";
              this.note = "";
              this.paid = false;
              this.bookingID = "";
              this.runID = "";
            } else {
              if (response.message.includes("409")) {
                this.snackbarToggle({
                  message: "This customer already booked in this raid.",
                  color: "red",
                });
              } else {
                this.snackbarToggle({
                  message: "Your booking failed! Please try again later.",
                  color: "red",
                });
              }
            }
          }
        }
        this.loading = false;
      }
    },
    async autofillCustomerInfo(): Promise<void> {
      this.loading = true;
      if (this.raiderIo) {
        const raiderIoIUrl: URL = new URL(this.raiderIo.replace(/\s+/g, ""));
        if (raiderIoIUrl.hostname === "raider.io") {
          const response = await this.$store.dispatch(
            "getRaiderIoInfo",
            this.raiderIo
          );
          if (response.status === 200) {
            this.buyer = `${response.data["name"]}-${response.data["realm"]}`;
            this.paymentRealm = response.data["realm"];
            this.paymentFaction = response.data["faction"];

            this.snackbarToggle({
              message: "Info autofilled",
              color: "green",
            });
            console.log(JSON.stringify(response.data));
          } else {
            this.snackbarToggle({
              message: "Couldn't fetch autofill data for this character",
              color: "red",
            });
          }
        } else {
          this.snackbarToggle({
            message: "no valid url for autofill",
            color: "red",
          });
        }

        this.loading = false;
      }
    },
    async updateBooking(shouldBook: boolean): Promise<void> {
      const form: any = this.$refs.form;
      if (shouldBook) {
        this.loading = true;
        form.validate();
        const booking: any = {
          time: this.time,
          advCut: this.advCut.toUpperCase(),
          typeOfBoost: this.type,
          buyer: this.buyer.trim(),
          price: parseFloat(this.pot),
          collectedGold: this.collectedGold
            ? parseFloat(this.collectedGold)
            : 0,
          paymentRealm: this.paymentRealm.replace(/\s+/g, ""),
          faction: this.paymentFaction,
          note: this.note + " " + this.btag,
          paid: this.paid,
          bookingId: this.bookingID,
        };
        const response = await this.$store.dispatch("updateShiftBooking", {
          booking: booking,
          raid: this.leaderId,
        });
        if (response.status === 200) {
          this.snackbarToggle({
            message: "Your booking has been successfully created.",
            color: "green",
          });
          this.resetForm();
        } else {
          if (response.message.includes("409")) {
            this.snackbarToggle({
              message: "This customer already booked in this raid.",
              color: "red",
            });
          } else {
            this.snackbarToggle({
              message: "Your booking failed! Please try again later.",
              color: "red",
            });
          }
        }
        this.loading = false;
      }
    },
    snackbarToggle(snackbarData: SnackbarData): void {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    loadBooking(bookingId): void {
      const sheetCustomer: SheetCustomer[] | undefined = this.loadCustomer();
      if (sheetCustomer !== undefined) {
        const booking: SheetCustomer | undefined = sheetCustomer.find(
          (b) => b.bookingId === bookingId
        );
        if (booking) {
          if (booking && booking.customerStatus !== "In Group") {
            const paymentRealm = this.paymentRealmOptions.find(
              (realm: string) =>
                realm.replace(/\s+/g, "") === booking.paymentRealm
            );
            this.time = booking.timeBooked;
            this.bookingID = booking.bookingId;
            this.type = booking.type as BoostType;
            this.buyer = booking.buyerName.trim();
            this.paymentRealm = paymentRealm
              ? paymentRealm
              : this.paymentRealmOptions[0];
            this.paymentFaction = booking.paymentFaction;
            this.pot = parseInt(booking.price) + "";
            this.collectedGold = booking.collected
              ? parseInt(booking.collected) + ""
              : "";
            this.advCut = booking.feeOption;
            this.note = booking.noteBtag ? booking.noteBtag : "";
            this.paid = booking.paid === "TRUE" ? true : false;
            this.runID = "" + booking.leaderId;
            this.leaderId = booking.leaderId;
          }
        }
      }
    },
    openDeleteConfirmationDialog(booking: SheetCustomer) {
      this.deleteConfirmDialog = true;
      this.deleteConfirmCustomer = booking;
      this.deleteConfirmAction = "cancelBooking";
    },
    moreThanFiveMinutesOld(booking: SheetCustomer) {
      const timeString = booking.timeBookingBooked;
      const timeMoment = moment(timeString, "HH:mm");

      const berlinTimeMoment = timeMoment.tz("Europe/Berlin");
      const currentBerlinTime = moment.tz("Europe/Berlin");

      const diffMinutes = currentBerlinTime.diff(berlinTimeMoment, "minutes");

      return diffMinutes > 5;
    },
    async cancelBooking() {
      const booking: any = this.deleteConfirmCustomer as SheetCustomer;
      if (booking) {
        let time = booking.timeBooked;
        booking.buyer = "Cancelled-Cancelled";
        booking.note = "Cancelled";
        booking.typeOfBoost = "Cancelled";
        booking.price = "0";
        booking.time = time;
        const response = await this.$store.dispatch("updateShiftBooking", {
          booking: { ...booking },
          raid: booking.leaderId,
        });
        if (response.status >= 200 && response.status < 400) {
          this.snackbarToggle({
            message: "Your booking has been successfully cancelled.",
            color: "green",
          });
          this.deleteConfirmDialog = false;
        } else {
          this.snackbarToggle({
            message: "Your booking cancel failed! Please try again later.",
            color: "red",
          });
        }
      }
    },
  },
  computed: {
    difficultyAbrevations() {
      return difficultyAbrevations;
    },
    computeSummaryValues: function () {
      const values: any = [];
      values.push({ label: "Faction", value: this.paymentFaction });
      values.push({ label: "FeeOption", value: this.advCut });
      values.push({ label: "Payment Realm", value: this.paymentRealm });
      values.push({
        label: "Payment Faction",
        value: this.paymentFaction,
      });
      return values;
    },
    allBookings: function () {
      let customers: Array<SheetCustomer> = [];
      customers = this.loadCustomer();
      return customers.reverse();
    },
    filteredRaids: function () {
      const raids: Array<SheetShift> = [];
      this.loadRuns().forEach((raid: SheetShift) => {
        let need = true;
        if (this.difficultyFilter && need) {
          if (this.difficultyFilter === raid.difficulty) {
            need = true;
          } else {
            need = false;
          }
        }
        if (need) {
          raids.push(raid);
        }
      });
      return raids;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.$store.dispatch("getSheetShifts");
      this.$store.dispatch("getSheetCustomer");
      this.$store.dispatch("getLeaders");
      this.advCut = this.$store.state.roles.some(
        (role) => role === "TAKENBOOKER"
      )
        ? ""
        : websiteAdvCutOptions[0];
      this.advCutOptions = this.$store.state.roles.some(
        (role) => role === "TAKENBOOKER"
      )
        ? websiteAdvCutOptions
        : websiteAdvCutOptions;

      if (this.$route.params.raidId) {
        this.SetIdForBooking(this.$route.params.raidId);
      }
    });
  },
  watch: {
    advCut: function () {
      if (this.advCut) {
        let advCut = "";
        for (let i = 0; i < this.advCut.length; i++) {
          advCut += this.advCut[i];
          let found = websiteAdvCutOptions.filter((co) => {
            const advCut = co.toLowerCase().replaceAll("'", "");
            const input = advCut.toLowerCase().replaceAll("'", "");
            return advCut.includes(input);
          });
          if (found.length === 1) {
            i = this.advCut.length + 1;
            this.advCut = found[0];
          }
        }
        if (this.pot) {
          if (this.advCut.toUpperCase() === "BALANCE") {
            this.paymentRealm = "BALANCE";
            this.paymentRealmOptions = [];
            if (this.paid) {
              this.collectedGold = this.pot;
            } else {
              this.collectedGold = "0";
            }
          } else if (this.advCut.toUpperCase() === "TAKEN") {
            if (this.paid) {
              this.collectedGold = this.pot;
            } else {
              this.collectedGold = (
                (BigInt(this.pot) * BigInt(300)) /
                BigInt(1000)
              ).toString();
            }
          } else {
            this.paymentRealmOptions = realmOptions;
            if (this.paid) {
              this.collectedGold = this.pot;
            } else {
              this.collectedGold = "0";
            }
          }
        }
      }
    },
    paymentRealm: function () {
      if (this.paymentRealm) {
        let realm = "";
        for (let i = 0; i < this.paymentRealm.length; i++) {
          realm += this.paymentRealm[i];
          let found = realmOptions.filter((pr) => {
            const paymentRealm = pr.toLowerCase().replaceAll("'", "");
            const input = realm.toLowerCase().replaceAll("'", "");
            return paymentRealm.includes(input);
          });
          if (found.length === 1) {
            i = this.paymentRealm.length + 1;
            this.paymentRealm = found[0];
          }
        }

        if (this.paymentRealm.toUpperCase() === "BALANCE") {
          this.advCutOptions = ["BALANCEC", "BALANCEO"];
          this.advCut = "";
        } else {
          this.advCutOptions = websiteAdvCutOptions;
        }
      }
    },
    collectedGold: function (val) {
      if (this.collectedGold && this.pot && this.advCut) {
        if (this.advCut === "TAKEN") {
          if (
            parseFloat(this.collectedGold) > parseFloat(this.pot) * 0.3 &&
            !this.paid
          ) {
            this.collectedGold = "0";
          }
        } else if (this.advCut === "FTB") {
          if (
            parseFloat(this.collectedGold) > parseFloat(this.pot) * 0.3 &&
            !this.paid
          ) {
            this.collectedGold = "0";
          } else {
            if (val > parseFloat(this.pot) * 0.3) {
              this.collectedGold = (parseFloat(this.pot) * 0.3).toLocaleString(
                "en-US",
                { minimumIntegerDigits: 2, useGrouping: false }
              );
            } else {
              this.collectedGold = val;
            }
          }
        }
      }
    },
    pot: function () {
      if (this.pot && this.advCut.toUpperCase() === "TAKEN" && !this.paid) {
        this.collectedGold = (parseFloat(this.pot) * 0.3).toLocaleString(
          "en-US",
          { minimumIntegerDigits: 2, useGrouping: false }
        );
      }
    },
    paid: function () {
      if (this.paid) {
        this.collectedGold = this.pot;
      } else {
        if (this.pot && this.advCut.toUpperCase() === "TAKEN" && !this.paid) {
          this.collectedGold = (parseFloat(this.pot) * 0.3).toLocaleString(
            "en-US",
            { minimumIntegerDigits: 2, useGrouping: false }
          );
        } else {
          this.collectedGold = "0";
        }
      }
    },
    paymentFaction: function (val) {
      if (val) {
        let paymentFaction = "";
        for (let i = 0; i < val.length; i++) {
          paymentFaction += val[i];
          let found = factions.filter((co) => {
            const faction = co.toLowerCase().replaceAll("'", "");
            const input = paymentFaction.toLowerCase().replaceAll("'", "");
            return faction.includes(input);
          });
          if (found.length === 1) {
            i = this.paymentFaction.length + 1;
            this.paymentFaction = found[0];
          }
        }
      }
    },
    type: function () {
      if (this.type) {
        let type = "";
        for (let i = 0; i < this.type.length; i++) {
          type += this.type[i];
          let found = shiftBossOptions.filter((pr) => {
            const type = pr.toLowerCase().replaceAll("'", "");
            const input = type.toLowerCase().replaceAll("'", "");
            return type.includes(input);
          });
          if (found.length === 1) {
            i = this.type.length + 1;
            this.type = found[0];
          }
        }
      }
    },
    "$store.state.sheetShifts": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
    "$store.state.sheetCustomers": {
      deep: true,
      handler: function () {
        this.loadCustomer();
      },
    },
  },
});
